<template>
	<article>
		<hero-image
			:alt="hero.alt"
			:heading="hero.heading"
			:img-small="hero.imgSmall"
			:img-large="hero.imgLarge"
		/>
		<v-container class="py-0">
			<v-row no-gutters align="center">
				<v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
					<breadcrumbs :crumbs="crumbs" />
				</v-col>
				<v-col cols="12" sm="5">
					<social :meta="meta" />
				</v-col>
			</v-row>
		</v-container>
		<v-container class="content" tag="section">
			<v-row>
				<v-col cols="12">
					<p class="blockquote">
						An academic assessment is conducted when there are concerns about a child’s learning ability. For example, parents may feel that their
						child is not learning at the rate of their peers, may engage in disruptive behaviour or may have difficulty with specific academic areas
						such as reading or maths.
					</p>
					<p>
						Children are diagnosed with learning disorders when performance on academic testing (such as reading, spelling, mathematics, written
						language, comprehension and oral language skills), is substantially below that expected for their age, education, and intellectual
						ability. Once the profile of strengths and weakness has been identified, appropriate and tailored intervention planning can be
						implemented within the classroom to provide an easier learning environment for your child.
					</p>
				</v-col>
				<v-col cols="12">
					<cta :color="$vuetify.theme.themes.light.academic" icon="$vuetify.icons.academic" />
				</v-col>
			</v-row>
		</v-container>
	</article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import Cta from '@/components/cta/Cta';
import HeroImage from '@/components/image/HeroImage';
import Social from '@/components/social/Social';
export default {
	name: 'AcademicAssessment',
	components: {
		Breadcrumbs,
		Cta,
		HeroImage,
		Social
	},
	mixins: [_MxAppViewClass, _MxMeta],
	data() {
		return {
			crumbs: [
				{
					text: 'home',
					disabled: false,
					to: '/'
				},
				{
					text: 'services',
					disabled: false,
					to: '/services'
				},
				{
					text: this.$route.name,
					disabled: true
				}
			],
			hero: {
				alt: this.$route.name,
				heading: this.$route.name,
				imgSmall: 'hero-03-small',
				imgLarge: 'hero-03-large'
			},
			meta: {
				description: 'Neuropsychological Academic Assessment for your child or adolescent from Sydney Kids Neuropsychology.',
				title: 'Neuropsychological Academic Assessment'
			}
		};
	}
};
</script>
